<script>
import { mapGetters } from 'vuex'
import IconClose from '../assets/icon-close.vue'
import { GET_USER } from '@/store/actions'
import Spinner from '@/components/Spinner'

export default {
  name: 'ViewCredentialCardModal',
  components: {
    IconClose,
    Spinner,
  },
  props: {
    userData: {
      required: true,
    },
    templateItens: {
      required: true,
    },
    frontendProps: {
      default: () => {},
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      isLoaded: false,
      backTemplate: null,
      templateValues: [],
      categories: [],
    }
  },
  computed: {
    ...mapGetters(['caName', 'photoURL']),
    ...mapGetters('template', [
      'excelTemplate',
      'tid',
      'caName',
      'credName',
      'urlPhoto',
      'cardHeaders',
      'customTemplateName',
    ]),
  },
  watch: {},
  mounted() {
    console.log('mounted', GET_USER)

    this.$store.dispatch(GET_USER, { id: this.userData.id }).then(({ data }) => {
      console.log('user', data)
      if (data.categories && data.categories.length > 0) {
        this.categories = data.categories
      }
    })
  },

  created() {
    console.log(this.userData)
    const frontTemplateObj = JSON.parse(JSON.stringify(this.userData.user_data))
    delete frontTemplateObj.tables
    console.log(this.templateItens)

    console.log('frontendProps', this.frontendProps)

    if (this.templateItens) {
      for (let index = 0; index < this.templateItens.length; index++) {
        const el = this.templateItens[index]
        console.log('el', el)

        // dont add the elements email and phone
        if (['email', 'phone'].includes(el.attr.toLowerCase())) {
          continue
        }

        if (['image'].includes(el.attr.toLowerCase())) {
          this.templateValues.unshift({
            attr: el.attr,
            value: this.userData.user_data[el.attr],
            type: el.type,
          })
        }
        else
          if (this.userData.user_data[el.attr]) {
            this.templateValues.push({
              attr: el.attr,
              value: this.userData.user_data[el.attr],
              type: el.type,
            })
          }
      }
      // insert an blank space after the image
      // this.templateValues.splice(1, 0, { attr: '', value: '', type: 'blank' })
    }
    else {
      for (const a in frontTemplateObj) {
        const val = frontTemplateObj[a]
        this.templateValues.push({ attr: a, value: val })
      }
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    cancel() {
      this.$emit('cancel')
    },
    onImgLoad() {
      this.isLoaded = true
    },
  },

}
</script>

<template>
  <transition name="modal-fade">
    <div v-show="true" class="modal-backdrop">
      <div
        class="modal-wallid card_modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header id="modalTitle" class="modal-header">
          <slot name="header">
            {{ $t('viewCredential.modal.title') }}

            <v-spacer />
            <button
              type="button"
              class="btn-close"
              aria-label="Close modal"
              @click="close"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body px-16 pb-16 pt-2 mb-1">
          <slot name="body">
            <v-col
              cols="12"
              md="12"
              class="pb-16 view-credential"
            >
              <v-row>
                <v-col cols="12">
                  <h2 class="title-header">
                    {{ $t('drivingLicense.subtitle') }}
                  </h2>
                </v-col>
                <v-col v-for="(item, index) in templateValues" :key="`attribute-${index}`" cols="6">
                  <div
                    v-if="item.type === 'blank'"
                  />

                  <v-img
                    v-else-if="item.type === 'image'"
                    :src="item.value"
                    width="80"
                    height="80"
                    class="mx-auto"
                    @load="onImgLoad"
                  />
                  <v-text-field
                    v-else
                    v-model="item.value"
                    :label="$t(`drivingLicense.attributes.${item.attr.toLowerCase()}`)"
                    readonly
                    outlined
                    dense
                    hide-details="false"
                  />
                </v-col>
                <v-col cols="12">
                  <h2 class="title-header">
                    {{ $t('drivingLicense.categories') }}
                  </h2>
                </v-col>
                <v-col v-for="(c, index) in categories" :key="`category-${index}`" cols="12">
                  <v-row>
                    <v-col cols="10">
                      <h4>
                        {{ c.gd }} - {{ c.title }}
                      </h4>
                    </v-col>

                    <v-col cols="4">
                      <div>
                        <label class="font-weight-medium"> Otorgado: </label>
                        {{ c.granted }}
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        <label class="font-weight-medium"> Válido desde: </label>
                        {{ c.validFrom }}
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        <label class="font-weight-medium"> Válido hasta: </label>
                        {{ c.validUntil }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <Spinner v-show="!isLoaded" />
            </v-col>
          </slot>
        </div>

        <!-- <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="delete" @click="cancel()">
                {{ $t('button.cancel') }}
              </v-btn>
            </v-row>
          </slot>
        </div> -->
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.modal-wallid.card_modal {
  position: relative;
 .view-credential {
    max-height: 460px;
    overflow: auto;
    padding-inline: 64px;
  }
  .title-header {
    border-bottom: solid 2px var(--teal-blue);
    width: fit-content;
  }

  .modal-body {
    max-height: 560px;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 12px !important;
  }
  .v-stepper {
    box-shadow: none;
  }
  .modal-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .container.scroll {
    max-height: 420px;
    overflow: auto;
  }
}
</style>
